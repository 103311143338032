* {
  box-sizing: border-box;
}

.app {
  background-color: #2D3234;
  min-height: 100vh;
  padding: 0 10%;
}

header {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
}